<template>
  <v-container :fill-height="issueReported" style="margin-top: 55px;">
    <v-row align="center" justify="center" style="margin-bottom: 100px;" v-if="issueReported">
      <v-col class="text-center">
        <div class="mb-3 font-weight-bold" style="font-size: 1.4rem;">
          Bedankt voor het melden!
        </div>
        <i class="fa-solid fa-circle-check fa-2x" style="color: #43A047"></i>
      </v-col>
    </v-row>

    <template v-else>
      <v-row v-if="loading" justify="center">
        <v-col cols="12" md="4">
          <v-skeleton-loader
              elevation="2"
              type="card-heading, list-item, list-item, list-item, image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <template v-if="!loading">
        <v-expansion-panels
            class="mb-3"
            v-model="firstPanel"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Machine</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form>
                <v-text-field label="Naam" readonly :value="machine.name"></v-text-field>
                <v-text-field label="Model" readonly :value="machine.model"></v-text-field>
                <v-text-field label="Serienummer" readonly :value="machine.serial_number"></v-text-field>
                <v-img :src="machine.image"></v-img>
              </v-form>
              <br>
              <v-row class="mt-2 mb-1">
                <h4>Extra informatie</h4>
              </v-row>
              <br>
              <v-row
                  align="center"
                  justify="space-around"
                  class="text-center mb-3"
                  style="font-size: small"
              >
                <v-btn color="primary" dark @click="openPDF(1)" class="mb-2"
                       :style="{ minWidth: isMobile ? '100%' : '24%'}">
                  Handleiding
                  <v-icon class="fa-solid fa-file-arrow-down ml-2"></v-icon>
                </v-btn>
                <v-btn color="green" dark @click="openPDF(2)" class="mb-2"
                       :style="{ minWidth: isMobile ? '100%' : '24%'}">
                  Uitleg iconen
                  <v-icon class="fa-solid fa-file-arrow-down ml-2"></v-icon>
                </v-btn>
                <v-btn color="warning" dark @click="openPDF(3)" class="mb-2"
                       :style="{ minWidth: isMobile ? '100%' : '24%'}">
                  Eigenschappen
                  <v-icon class="fa-solid fa-file-arrow-down ml-2"></v-icon>
                </v-btn>
                <v-btn color="red" dark @click="goToYTlink()" class="mb-2"
                       :style="{ minWidth: isMobile ? '100%' : '24%'}">
                  Noodprocedure
                  <v-icon class="fab fa-youtube ml-2"></v-icon>
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>
        <v-btn
            v-if="step === 1"
            @click="goToStep(2)"
            block
            color="primary"
            elevation="1"
        >
          Probleem melden
        </v-btn>
      </template>

      <template v-if="step !== 1">
        <v-expansion-panels class="mb-3" v-model="secondPanel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Probleem melden</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="secondForm">
                <v-combobox
                    v-model="issue.type"
                    label="Type"
                    :items="problemTypes"
                    :rules="[v => !!v || 'Veld is verplicht']"
                ></v-combobox>
                <v-textarea
                    v-model="issue.description"
                    :rules="[v => !!v || 'Veld is verplicht']"
                    label="Omschrijving"
                ></v-textarea>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn
            v-if="step === 2"
            block
            @click="goToStep(3)"
            color="primary"
            elevation="1"
        >
          Volgende
        </v-btn>
      </template>

      <template v-if="step === 3">
        <v-expansion-panels class="mb-3" v-model="thirdPanel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3>Contact informatie</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="thirdForm" lazy-validation>
                <v-text-field
                    v-model="reporter.name"
                    label="Naam"
                    :rules="[v => !!v || 'Veld is verplicht']"
                ></v-text-field>
                <v-text-field
                    v-model="reporter.email"
                    label="Email"
                    :rules="[
                        v => !!v || 'Veld is verplicht',
                        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email is ongeldig'
                    ]"
                ></v-text-field>
                <vue-tel-input-vuetify
                    placeholder=""
                    label="Telefoonnummer"
                    :preferred-countries="['nl']"
                    :valid-characters-only="true"
                    @input="onInput"
                    :rules="[v => !!v || 'Veld is verplicht']"
                ></vue-tel-input-vuetify>
                <v-row>
                  <v-col cols="12">
                    <div v-if="phone.number" style="color: #e83e8c">
                    <span>
                      Nummer: <strong>{{ phone.number }}</strong>
                    </span>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-btn
            @click="goToStep(4)"
            block
            color="primary"
            elevation="1"
            :loading="requestInProgress"
        >
          Verstuur
        </v-btn>
      </template>
    </template>
  </v-container>
</template>

<script>
import axios from "@/utils/axios";

export default {
  name: 'RegistrationForm',

  data() {
    return {
      isMobile: false,
      firstPanel: 0,
      secondPanel: 0,
      thirdPanel: 0,
      step: 1,
      machine: {},
      loading: true,
      requestInProgress: false,
      issueReported: false,
      reporter: {
        name: null,
        email: null,
        phone: null,
      },
      issue: {
        type: null,
        description: null,
      },
      phone: {
        number: null,
      }
    }
  },

  computed: {
    problemTypes() {
      return this.machine.problem_choices.map((choice) => {
        return {
          text: choice.display,
          value: choice.id
        }
      })
    }
  },

  methods: {
    onInput(formattedNumber, {number}) {
      this.reporter.phone = number.international;

      this.phone.number = number.international;
    },

    validateSecondStep() {
      return this.$refs.secondForm.validate()
    },

    validateThirdStep() {
      return this.$refs.thirdForm.validate()
    },

    goToStep(nextStep) {
      if (nextStep === 2) {
        this.firstPanel = null;
        this.step = 2;

        return;
      }

      if (nextStep === 3) {
        const valid = this.validateSecondStep();

        if (!valid) {
          return;
        }

        this.secondPanel = null;
        this.step = 3;

        return;
      }

      if (nextStep === 4) {
        const valid = this.validateThirdStep();

        if (!valid) {
          return;
        }

        this.submitRequest();

        return;
      }

      this.step = 1;
    },

    async submitRequest() {
      const data = {
        name: this.reporter.name,
        email: this.reporter.email,
        phonenumber: this.reporter.phone,
        machineIssueId: this.issue.type.value,
        issueDescription: this.issue.description,
      };

      this.requestInProgress = true;

      setTimeout(() => {
        this.requestInProgress = false;
        this.issueReported = true;
      }, 1500);

      try {
        await axios.post(`/qr/${this.$route.params.serialNumber}/post`, data);
      } catch (error) {
        console.error(error);
      }
    },

    goToYTlink() {
      window.open('https://www.youtube.com/watch?v=c-XfkCls-DQ', '_blank');
    },

    async openPDF(index) {
      window.open(`https://m-sa.wablication.com/pdf/pdfTest${index}.pdf`, '_blank');

      // axios
      //   .get(`/openPdf/${index}/get`)
      //   .then(response => {
      //     const url = response.data.pdfBase64;
      //
      //   })
      //   .catch(() =>  {
      //     this.$router.push('/not-found');
      //   })
    },
    checkDevice() {
      this.isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed
    }
  },

  async created() {
    this.loading = true;

    try {
      const result = await axios.get(`/qr/${this.$route.params.serialNumber}/get`);

      this.machine = result.data.machine;

      this.loading = false;
    } catch (error) {
      this.$router.push('/not-found');
    }
    this.checkDevice();
    window.addEventListener('resize', this.checkDevice);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkDevice);
  },
}
</script>
