import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import RegistrationForm from "@/components/RegistrationForm";
import NotFound from "@/components/NotFound";
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

Vue.use(VueTelInputVuetify, {vuetify});
Vue.use(VueRouter);
Vue.config.productionTip = false;

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/machine/:serialNumber', component: RegistrationForm },
        { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
    ]
});

new Vue({
    vuetify,
    router: router,
    render: h => h(App)
}).$mount('#app')
