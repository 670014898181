import axios from 'axios';
import * as https from "https";

const instance = axios.create();

instance.interceptors.request.use(
    (config) => {
        config.baseURL = process.env.VUE_APP_API_ENDPOINT;

        if (process.env.VUE_APP_ENV === 'development') {
            config.httpsAgent = new https.Agent({
                rejectUnauthorized: false
            })
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        return Promise.reject(error);
    }
);

export default instance;
