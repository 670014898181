<template>
  <v-app :style="{background: $vuetify.theme.themes['dark'].background}">
    <v-app-bar dark app sticky>
      <i class="fa-solid fa-wrench mr-2"></i>
      <v-app-bar-title>Machine Service Application</v-app-bar-title>
    </v-app-bar>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

